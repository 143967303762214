<div class="i-carbon-logo-python pointer-events-none absolute right-0 top-1/2 text-[clamp(9rem,50vw-7rem,22.5rem)] op-3 -translate-y-1/2" />

<div class="h-full min-h-sm flex flex-col justify-between">
  <div class="flex flex-row items-center gap-2">
    <a class="w-fit flex flex-row select-none items-center gap-1 rounded-full bg-white/5 p-2 pr-2.5 transition hover:(bg-white text-neutral-9)" href="https://github.com/promplate/pyth-on-line">
      <div class="i-mdi-github" />
      <h2 class="contents">
        <div class="flex flex-row gap-0.3 text-xs tracking-wider">
          promplate
          <span class="op-60">/</span>
          pyth-on-line
        </div>
      </h2>
    </a>
    <a class="rounded-full bg-white/5 p-2 transition hover:(bg-white text-neutral-9 -rotate-45)" href="https://github.com/promplate/pyth-on-line" target="_blank">
      <div class="i-ic-twotone-arrow-forward" />
    </a>
  </div>

  <div class="flex flex-col translate-x-0.5 gap-3 lg:gap-5">
    <div class="text-xs">
      <span class="op-30">由一个将 CPython 编译成 WASM 的项目</span>
      <a class="underline underline-(white op-30 offset-2) op-80 transition hover:op-100" href="https://github.com/pyodide/pyodide">@pyodide</a>
      <span class="op-30">实现</span>
    </div>

    <h1 class="flex flex-row select-none text-4xl font-sans font-semibold tracking-wide -translate-x-0.5">
      <span>Pyth</span>
      <span>on</span>
      <span>line</span>
    </h1>

    <div>
      <h2 class="group w-fit flex flex-row gap-1.5 rounded-sm bg-neutral-1 px-2.5 py-1.5 text-sm text-neutral-9 font-500">
        浏览器中的 Python IDE，内置 Copilot
        <span class="hidden select-none group-hover:(block after:content-['🥰'])" />
      </h2>
    </div>
  </div>

  <div />
</div>
